/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

function CandidSouldFAQ({ noHeader, lang }) {
  const [questions, setQuestions] = useState([]);
  const toggleAnswer = (id, e) => {
    e.preventDefault();
    const cloneQuestions = [...questions];
    cloneQuestions.forEach((faq) => {
      if (faq.id === id) {
        faq.isOpen = !faq.isOpen;
      }
    });
    setQuestions(cloneQuestions);
  };

  const faq = {
    en: [
      {
        id: 1,
        title: 'What is the Candid Soul Co. Loyalty Program?',
        answer: 'Our loyalty program is a way to thank you for your continued support and engagement with Candid Soul Co. As a member, you’ll earn exclusive rewards, enjoy special discounts, access early releases, and much more, all while being part of a community that supports your spiritual journey.',
      },
      {
        id: 2,
        title: 'How do I join the Loyalty Program?',
        answer: 'Joining is easy! Simply create an account on our website and you’ll automatically be enrolled in our loyalty program at http://www.candidsoulco.com  From there, you can earn points and unlock higher tiers with increased benefits.',
      },
      {
        id: 3,
        title: 'What are the different tiers and how do they work?',
        answer: 'We have three tiers in our loyalty program: <ul><li>Insider: For new members just starting their journey. You’ll enjoy introductory perks like our inspiring  newsletter and promotional days.</li><li>Seeker: As you engage more with Candid Soul Co., you’ll unlock additional benefits like early access to new releases, giveaways and more exclusive content.</li><li>Believer: Our highest tier, where you’ll receive the most comprehensive benefits, including exclusive discounts one mentoring sessions, gifts, events, and much more.</li></ul>You move up the tiers based on your level of engagement and spending with Candid Soul Co.',
      },
      {
        id: 4,
        title: 'How do I earn points in the Loyalty Program?',
        answer: 'You can earn points through various activities, including:<ul><li>Making purchases on our website</li><li>Referring friends to join the program</li><li>Participating in monthly challenges</li><li>Engaging with our content (e.g., sharing posts, attending webinars)</li></ul>Points can be redeemed for discounts, and other special rewards.',
      },
      {
        id: 5,
        title: 'What are Promotional Days, and how do they work?',
        answer: 'Promotional Days are special days throughout the year where you can earn double points, access flash sales, and receive anniversary rewards. These days are announced in advance, so be sure to keep an eye on your email and our social media channels!',
      },
      {
        id: 6,
        title: 'Can I earn rewards by referring friends?',
        answer: 'Absolutely! For every friend you refer who joins the Candid Soul Co. Loyalty Program, you’ll earn points. The more friends you refer, the more rewards you can unlock. Plus, both you and your friend will receive bonus points as a thank you.',
      },
      {
        id: 7,
        title: 'What exclusive content do I get as a member?',
        answer: 'As a loyalty program member, you’ll have access to content that’s not available to the public. This includes behind-the-scenes videos, member spotlights, advanced spiritual guidance, and early previews of new products and courses.',
      },
      {
        id: 8,
        title: 'How do I redeem my points?',
        answer: 'Redeeming your points is simple. Log into your account, navigate to the rewards section, and choose how you’d like to use your points—whether for discounts, free products, or other special offers.',
      },
      {
        id: 9,
        title: 'Do my points expire?',
        answer: 'Points typically have a one-year expiration period, so be sure to use them before they’re gone. We’ll send reminders as your points are close to expiring, so you don’t miss out on any rewards.',
      },
      {
        id: 10,
        title: 'How do I get the Birthday Month Gift?',
        answer: 'As a thank you for being part of the Candid Soul Co. family, we’ll send you a special gift during your birthday month. Make sure your birthdate is entered in your account profile so we can celebrate with you!',
      },
      {
        id: 11,
        title: 'How do I get 50% off shipping?',
        answer: 'Members in certain tiers will automatically receive 50% off shipping on all orders. Simply log in to your account when making a purchase, and the discount will be applied at checkout.',
      },
      {
        id: 12,
        title: 'How do I contact customer support if I have more questions?',
        answer: 'If you have any further questions, our customer support team is here to help! You can reach out to us via our contact page on the website, or send us an email at getcandid@candidsoulco.com.',
      },
    ],
  };

  useEffect(() => {
    setQuestions(faq.en);
  }, []);

  return (
    <div className="asset-faq">
      {!noHeader
      && <h2 className="digital-asset__subheader -center">FAQ</h2>}
      {questions.map((faq) => (
        <div
          key={faq.id}
          className="asset-faq__item">
          {faq.isOpen
            ? (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                -
              </a>
            )
            : (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                +
              </a>
            )}
          <div
            className="asset-faq__item--question"
            onClick={(e) => toggleAnswer(faq.id, e)}>
            {faq.title}
          </div>
          <div
            className={faq.isOpen ? 'asset-faq__item--answer -open' : 'asset-faq__item--answer'}
            dangerouslySetInnerHTML={{ __html: faq.answer }}>
          </div>
        </div>
      ))}
      
    </div>
  );
}

export default CandidSouldFAQ;
