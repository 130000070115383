import React, { useState, useEffect } from 'react';

const HowItWorks = ({ asset }) => {
  // const [transferCode, setTransferCode] = useState(null);
  const instructions = [
    {
      id: 1,
      title: 'Join',
      description: 'Simply sign up by entering your email address above!',
    },
    {
      id: 2,
      title: 'Check your email',
      description: 'You’ll get an email from us with your login link. Click through to validate your account and start exploring your membership!',
    },
    {
      id: 3,
      title: 'Start earning',
      description: 'Every time you shop and share, you can earn points that turn into discounts, get perks like early access to sales, new collections and contests, and view exclusive styling and behind the scenes content you won’t find anywhere else!',
    },
  ];

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="how-it-works__wrapper">
      <div id="startContent" className="how-it-works">
        <div className="how-it-works__title">
          How it works
        </div>
        <div className="how-it-works__list">
          {instructions.map((step, index) => (
            <div key={step.id} className="how-it-works__item">
              <div className="how-it-works__number">{index + 1}</div>
              <div className="how-it-works__step-title">{step.title}</div>
              <div className="how-it-works__step-description">{step.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default HowItWorks;
