import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { get } from 'jquery';
import Throbber from '../throbber';
import Account from '../../managers/Account';
import { getBlockchainType, getParameterByName } from '../../managers/Helpers';

const emailPattern = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorId: process.env.CREATOR_ID,
      loginEmail: '',
      resetEmail: '',
      loginPassword: '',
      isLoading: false,
      resetPassword: false,
      resetSuccess: false,
      loginVerification: '',
      loadingText: 'Logging you in! One second...',
      enableMFA: false,
      showMFA: false,
      userId: '',
      verifiedAccount: false,
      isUnverifiedAccount: false,
      noAccount: false,
      loginToken: null,
      emailSent: false,
    };
  }

  syncMembership = async (token) => {
    // make sure the user always has a membership before attempting to sync or go to the collections page

    try {
      if (process.env.VERIFIED_ACCOUNTS_ONLY) {
        const transferPayload = {
          transferToken: process.env.EDROP_CLAIM_TOKEN,
        };
        await Account.transUnlimitedEdrop(token, transferPayload);
      }
      if (process.env.MEMBERSHIP_AUTO_SYNC && process.env.CREATOR_ID && process.env.ASSET_ID) {
        const syncPayload = {
          creatorId: process.env.CREATOR_ID,
          assetId: process.env.ASSET_ID,
        };
        const syncResponse = await Account.syncMembership(token, syncPayload);
      }
    } catch (e) {
      toast.error('Error claiming membership. Please try again later.');
    }
  }

  validateForm = () => {
    const { loginEmail } = this.state;
    const { loginPassword } = this.state;
    const { onFail } = this.props;
    if (loginEmail === '' || loginPassword === '') {
      onFail('Please enter an email and password.');
      return false;
    }
    if (!emailPattern.test(loginEmail)) {
      onFail('Please enter a valid email address.');
      return false;
    }
    return true;
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  focusVerifcation = () => {
    setTimeout(() => {
      document.getElementById('loginVerification').focus();
    }, 1000);
  }

  logIn = async (e) => {
    const { loginEmail, loginPassword } = this.state;
    const { onSuccess, onFail } = this.props;
    let payload = {};
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({
        isLoading: true,
        loadingText: 'Logging you in! One second...',
      });
      payload = {
        email: loginEmail,
        password: loginPassword,
      };
      const login = await Account.login(payload);
      // if MFA is enabled
      if (login && login.userId) {
        this.setState({
          userId: login.userId,
          showMFA: true,
          isLoading: false,
        });
        this.focusVerifcation();
      // if MFA is NOT enabled
      } else if (login && login.token) {
        await Account.get(login.token);
        // if we have a creatorId and an assetId we can try syncing the account
        await this.syncMembership(login.token);
        Account.setLoginState(login.token);
        onSuccess();
      } else {
        this.setState({
          isLoading: false,
        });
        // toast.error(login.message || 'Oops! Something went wrong. Please try again later.');
        onFail('Incorrect email or password.');
      }
    }
  }

  mfaLogIn = async (e) => {
    const { userId, loginVerification } = this.state;
    const { onSuccess, onFail } = this.props;
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    const payload = {
      userId,
      token: loginVerification,
    };
    const login = await Account.mfaLogin(payload);
    if (login && login.token) {
      await Account.get(login.token);
      // if we have a creatorId and an assetId we can try syncing the account
      await this.syncMembership(login.token);
      Account.setLoginState(login.token);
      onSuccess();
    } else {
      this.setState({
        isLoading: false,
      });
      // toast.error(login.message || 'Oops! Something went wrong. Please try again later.');
      onFail('Incorrect verification code.');
    }
  }

  onEnterKey = (e) => {
    const { verifiedAccount, showMFA } = this.state;
    if (e.keyCode === 13) {
      if (!verifiedAccount && e.target.id === 'loginEmail') {
        this.checkEmailAddress();
      } else if (showMFA) {
        this.mfaLogIn(e);
      } else {
        this.logIn(e);
      }
    }
  };

  generateUnverifiedSession = async () => {
    const { loginEmail, loginToken } = this.state;
    const payload = {
      creatorId: process.env.CREATOR_ID,
      email: loginEmail.replace(/\s+/g, '+'),
      token: loginToken,
    };
    const getLoginToken = await Account.getLoginToken(payload);
    if (getLoginToken && getLoginToken.token) {
      // log the user in
      // if we have a creatorId and an assetId we can try syncing the account
      await this.syncMembership(getLoginToken.token);
      Account.setLoginState(getLoginToken.token);
      window.location.href = '/account/collection';
    } else {
      toast.error('Log in token has expired. Please try again.');
      this.setState({
        isLoading: false,
      });
    }
    console.log(getLoginToken);
  }

  componentDidMount() {
    if (localStorage.getItem(process.env.CREATOR_SUBDOMAIN + 'PreRegExistingInfo')) {
      this.setState({
        loginEmail: JSON.parse(localStorage.getItem(process.env.CREATOR_SUBDOMAIN + 'PreRegExistingInfo')).email,
      });
    }
    // check for a loginToken in the URL string
    if (getParameterByName('loginToken')) {
      // alert('has login token: ' + getParameterByName('loginToken'));
      this.setState({
        loginEmail: getParameterByName('email').replace(/\s+/g, '+'),
        isLoading: true,
        loginToken: getParameterByName('loginToken'),
      }, function () {
        this.generateUnverifiedSession();
      });
    }
  }

  updatePrePopulatedEmail = (email) => {
    this.setState({
      loginEmail: email,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { prepopulateEmail } = this.props;
    if (prevProps.prepopulateEmail !== prepopulateEmail) {
      this.updatePrePopulatedEmail(prepopulateEmail);
    }
  }

  resetPass = (e) => {
    e.preventDefault();
    this.setState({
      resetPassword: true,
    });
  }

  closeModal = () => {
    const { onRegisterButton } = this.props;
    if (onRegisterButton) {
      onRegisterButton();
    }
  }

  tryAgain = (e) => {
    e.preventDefault();
    this.setState({
      emailSent: false,
      loginEmail: '',
    });
  }

  sendResetEmail = async (e) => {
    const { resetEmail, creatorId } = this.state;
    const { onFail } = this.props;
    const that = this;
    e.preventDefault();
    if (!emailPattern.test(resetEmail)) {
      onFail('Please enter a valid email address.');
    } else {
      const payload = {
        creatorId,
        emailAddress: resetEmail,
        site: window.location.protocol + '//' + window.location.host,
      };
      this.setState({
        loadingText: 'Preparing your reset password email. One sec...',
        isLoading: true,
      });
      const resetPass = await Account.resetPassword(payload);
      if (resetPass && resetPass.success) {
        that.setState({
          isLoading: false,
          resetSuccess: true,
        });
      } else {
        onFail('Oops! Something went wrong. Please try again later.');
      }
    }
  }

  checkEmailAddress = async (e) => {
    const { loginEmail } = this.state;
    this.setState({
      isLoading: true,
      loadingText: 'Verifying email address. One sec...',
    });
    if (e) {
      e.preventDefault();
    }
    if (!emailPattern.test(loginEmail)) {
      this.setState({
        isLoading: false,
      });
      toast.error('Please enter a valid email address.');
    } else {
      const checkEmail = await Account.emailSearch(null, loginEmail);
      if (checkEmail && checkEmail.emailExists === true) {
        console.log('email does exist');
        if (checkEmail.isVerified) {
          if (process.env.VERIFIED_ACCOUNTS_ONLY) {
            const payload = {
              email: loginEmail,
              brandId: process.env.CREATOR_ID,
              membershipId: process.env.ASSET_ID,
            };
            const createAccount = await Account.VerifiedLogin(payload);
            if (createAccount && createAccount.success) {
              this.setState({
                emailSent: true,
              });
              // toast.success('Success! A login link has been sent to your inbox.');
            }
          } else {
            // user is verified, show the password field
            this.setState({
              verifiedAccount: checkEmail.isVerified,
            });
            const passwordField = document.getElementById('loginPassword');
            passwordField.focus();
            toast.success('Email address found. Enter your password to log in.');
          }
          this.setState({
            isLoading: false,
          });
        } else {
          // user is not verified, show the unverified message
          const payload = {
            creatorId: process.env.CREATOR_ID,
            email: loginEmail,
          };
          const loginLink = await Account.sendLoginEmail(payload);

          toast.success('Email address found. An email has been sent to your inbox.');
          this.setState({
            verifiedAccount: false,
            isUnverifiedAccount: true,
            isLoading: false,
          });
        }
      } else {
        console.log('email does not exist');
        // TODO: NOW go claim the NFT
        // await createUnverifiedAccount();
        toast.error('An account with this email does not exist.');
        this.setState({
          isLoading: false,
          noAccount: true,
        });
      }
    }
  };

  render() {
    const {
      isUnverifiedAccount, noAccount, verifiedAccount, loginEmail, loginPassword, isLoading, resetPassword, resetEmail, resetSuccess, loadingText, loginVerification, showMFA, emailSent,
    } = this.state;
    const {
      prepopulateEmail,
    } = this.props;
    return (
      <div>
        {isLoading
              && <Throbber throbberText={loadingText} />}
        {!resetPassword
          ? (
            <div>
              {showMFA
                ? (
                  <div>
                    <div className="account-subheading">
                      Please enter the verification code we sent to your phone. This code will expire in 30 minutes.
                    </div>
                    <div className="form-container">
                      <div className="form-section">
                        <label className="label-block -inverse" htmlFor="loginVerification">Verification Code</label>
                        <input
                          className="input-verification"
                          id="loginVerification"
                          type="text"
                          value={loginVerification}
                          maxLength="6"
                          onChange={(e) => this.handleInputChange(e)}
                          onKeyUp={(e) => this.onEnterKey(e)} />
                      </div>
                    </div>
                    <div className="form-cta">
                      <button
                        type="submit"
                        onClick={(e) => this.mfaLogIn(e)}>
                        Log in
                      </button>
                    </div>
                    <div className="form-help">
                      {'Didn\'t receive a code?'}
                      {' '}
                      Contact our
                      {' '}
                      <a href="mailto:hello@ethosnft.com">support team</a>
                      .
                    </div>
                  </div>
                )
                : (
                  <div>
                    {process.env.CUSTOM_LOGIN_MESSAGE
                      ? (
                        <>
                          {emailSent ? (
                            <>
                              <div className="account-subheading -slim-padding">
                                Check your inbox! We sent a log in email to
                                {' '}
                                {loginEmail}
                                . Click the link to log in.
                              </div>
                              <div className="form-cta">
                                Wrong email?
                                {' '}
                                <a
                                  className="form-try-again"
                                  href="#"
                                  onClick={(e) => this.tryAgain(e)}>
                                  Try again
                                </a>
                              </div>
                            </>
                          ) : (
                            <div
                              className="account-subheading -slim-padding"
                              dangerouslySetInnerHTML={{ __html: process.env.CUSTOM_LOGIN_MESSAGE }}>
                            </div>
                          )}
                        </>
                      )
                      : (
                        <div className="account-subheading -slim-padding">
                          Already have an ethos account?
                          <br />
                          Enter your email to log in.
                        </div>
                      )}
                    <div className="form-container">
                      {!emailSent
                        && (
                        <div className="form-section">
                          <label className="label-block" htmlFor="loginEmail">Email</label>
                          <input
                          className="input-block"
                          id="loginEmail"
                          type="text"
                          autoComplete="new-email"
                          value={loginEmail}
                          onChange={(e) => this.handleInputChange(e)}
                          onKeyUp={(e) => this.onEnterKey(e)} />
                        </div>
                        )}
                      
                      {verifiedAccount
                      && (
                      <div className="form-section">
                        <label className="label-block" htmlFor="loginPassword">Password</label>
                        <input
                          className="input-block"
                          id="loginPassword"
                          autoComplete="new-password"
                          type="password"
                          value={loginPassword}
                          onChange={(e) => this.handleInputChange(e)}
                          onKeyUp={(e) => this.onEnterKey(e)} />
                      </div>
                      )}
                      {isUnverifiedAccount && (
                        <div className="form-section">
                          This is an unverified account. Please check your inbox for a log in link.
                        </div>
                      )}
                      {noAccount
                      && (
                      <div className="form-section">
                        An account does not exist for this email. Click the
                        {' '}
                        <strong>Register Now</strong>
                        {' '}
                        button below to create an account with this email address.
                      </div>
                      )}
                    </div>
                    {!emailSent
                    && (
                    <div>
                      {verifiedAccount ? (
                        <div className="form-cta">
                          <button
                        type="submit"
                        onClick={(e) => this.logIn(e)}>
                            Log in
                          </button>
                        </div>
                      )
                        : (
                          <div className="form-cta">
                            {process.env.VERIFIED_ACCOUNTS_ONLY ? (
                              <button
                                type="submit"
                                onClick={(e) => this.checkEmailAddress(e)}>
                                Log in
                              </button>
                            ) : (
                              <button
                            type="submit"
                            onClick={(e) => this.checkEmailAddress(e)}>
                                Next
                              </button>
                            )}
                          </div>
                        )}
                    </div>
                    )}
                    {!process.env.VERIFIED_ACCOUNTS_ONLY
                    && (
                    <div className="form-help">
                      Trouble logging in?
                      {' '}
                      <a
                        href="#"
                        onClick={(e) => this.resetPass(e)}>
                        Click here
                      </a>
                      .
                    </div>
                    )}
                    {/* <div className="account-register__cta">
                      <div className="account-subheading -slim-padding">
                        {'Don\'t have an ethos account?'}
                      </div>

                      <div className="form-cta">
                        <Link
                          to="/register"
                          className="button button-register"
                          onClick={() => this.closeModal()}>
                          Register Now
                        </Link>
                      </div>
                    </div> */}
                  </div>
                )}
            </div>
          )
          : (
            <div>
              <div className="account-heading">
                Reset Password
              </div>

              {resetSuccess
                ? (
                  <div>
                    <div className="account-subheading">
                      Instructions on resetting your password have been sent to your inbox.
                    </div>
                  </div>
                )
                : (
                  <div>
                    <div className="account-subheading">
                      To reset your password, enter your email address below.
                      <br />
                      {'If this email is attached to an ethos account, we\'ll send you instructions to your inbox.'}
                    </div>

                    <div className="form-container">
                      <div className="form-section">
                        <label className="label-block -inverse" htmlFor="resetEmail">Email</label>
                        <input
                          className="input-block"
                          id="resetEmail"
                          type="text"
                          value={resetEmail}
                          onChange={(e) => this.handleInputChange(e)}
                          onKeyUp={(e) => this.onEnterKey(e)} />
                      </div>
                    </div>
                    <div className="form-cta">
                      <button
                        type="submit"
                        onClick={(e) => this.sendResetEmail(e)}>
                        Reset my password
                      </button>
                    </div>
                  </div>
                )}

            </div>
          )}
      </div>
    );
  }
}

LoginForm.propTypes = {
  prepopulateEmail: PropTypes.string,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  onRegisterButton: PropTypes.func,
};

export default LoginForm;
