/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const LoyaltyBenefits = ({ lang }) => {
  const copyStrings = {
    en: {
      heading: 'What do I get with a Candid Soul Tribe Loyalty Program?',
      benefit1: 'Referral Bonus',
      benefit2: 'Early Access',
      benefit3: 'Exclusive Discounts',
    },
  };

  const settings = {
    dots: true,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="storefront-benefits">
      {/* <div className="storefront-benefits__overlay"></div> */}
      <div className="storefront-benefits__inner">
        <div className="storefront-benefits__title">{copyStrings[lang].heading}</div>
        <div className="custom-benefits__row">
          <Slider {...settings}>
            <div>
              <div className="storefront-benefits__item">
                <div
                    className="storefront-benefits__item--image"
                    style={{
                      backgroundImage: 'url(https://assets.heyethos.com/candidsoul/benefits/thumb-1.jpg)',
                    }}>
                </div>
                <div className="storefront-benefits__item--title"><p>{copyStrings[lang].benefit1}</p></div>
              </div>
            </div>
            <div>
              <div className="storefront-benefits__item">
                <div
                    className="storefront-benefits__item--image"
                    style={{
                      backgroundImage: 'url(https://assets.heyethos.com/candidsoul/benefits/thumb-6.jpg)',
                    }}>
                </div>
                <div className="storefront-benefits__item--title"><p>{copyStrings[lang].benefit2}</p></div>
              </div>
            </div>
            <div>
              <div className="storefront-benefits__item">
                <div
                    className="storefront-benefits__item--image"
                    style={{
                      backgroundImage: 'url(https://assets.heyethos.com/candidsoul/benefits/thumb-7.jpg)',
                    }}>
                </div>
                <div className="storefront-benefits__item--title"><p>{copyStrings[lang].benefit3}</p></div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};
export default LoyaltyBenefits;
