import React, { useState, useEffect } from 'react';

const CandidSoulTiers = ({ lang }) => {
  const [showTiers, setShowTiers] = useState(false);

  const copyStrings = {
    en: {
      heading: 'What\'s my Candid Soul Tribe tier?',
      tableHeading: 'Benefits',
      tableColHeader1: 'Insider',
      tableColDesc1: 'Free',
      tableColHeader2: 'Seeker',
      tableColDesc2: '$120 Spend',
      tableColHeader3: 'Believer',
      tableColDesc3: '$250 Spend',
      tableRowDesc1: 'Newsletter',
      tableRowDesc2: 'Promotional Days',
      tableRowDesc3: 'Referral Bonus',
      tableRowDesc4: 'Early Access',
      tableRowDesc5: 'Exclusive Content',
      tableRowDesc6: 'Exclusive Discounts',
      tableRowDesc7: '50% off Shipping',
      tableRowDesc8: 'Birthday Month Gift',
    },
  };

  const toggleTiers = () => {
    setShowTiers(!showTiers);
  };

  useEffect(() => {
    // on mount
  }, []);

  return (
    <div className="custom-tiers__breakdown">
      <div className="custom-tiers__inner">
        <div className="custom-tiers__heading" onClick={(e) => toggleTiers()}>
          {copyStrings[lang].heading}
          <a><img className={showTiers ? '-rotate' : ''} src="https://assets.heyethos.com/bonlook/landing/icon-down-arrow.png" alt="What’s my Trendsetter tier?" /></a>
        </div>
        {showTiers
        && (
        <div className="custom-tiers__table">
          <table>
            <tr>
              <th>{copyStrings[lang].tableHeading}</th>
              <th>
                {copyStrings[lang].tableColHeader1}
                <span>{copyStrings[lang].tableColDesc1}</span>
              </th>
              <th>
                {copyStrings[lang].tableColHeader2}
                <span>{copyStrings[lang].tableColDesc2}</span>
              </th>
              <th>
                {copyStrings[lang].tableColHeader3}
                <span>{copyStrings[lang].tableColDesc3}</span>
              </th>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc1}</td>
              <td>&middot;</td>
              <td>&middot;</td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc2}</td>
              <td>&middot;</td>
              <td>&middot;</td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc3}</td>
              <td>&middot;</td>
              <td>&middot;</td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc4}</td>
              <td></td>
              <td>&middot;</td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc5}</td>
              <td></td>
              <td>&middot;</td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc6}</td>
              <td></td>
              <td>&middot;</td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc7}</td>
              <td></td>
              <td></td>
              <td>&middot;</td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc8}</td>
              <td></td>
              <td></td>
              <td>&middot;</td>
            </tr>
          </table>
        </div>
        )}
      </div>
    </div>
  );
};
export default CandidSoulTiers;
